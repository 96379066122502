import { Btn } from '../components/Btn'

export const NotFound = (props: any) => {
  return (
    <div>
      <h1>404</h1>
      <p>Page Not Found</p>
      <Btn onClick={() => window.history.back()} text="Go Back"/>
    </div>
  );
};

