import React from "react";
import { NotFound } from "./screens/NotFound";
import { Nav } from "./components/nav/Nav";
import { Container } from "./components/Container";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import logo from "./logo.svg";
import "./App.scss";

const App = () => {
  return (
    <Router>
      <div className="container">
        <div className="header center">
          <h1>Degenerate Web Chat Header</h1>
        </div>
        <div className="content">
          <div className="sidebar">
              <Link to="/"> Home </Link>
              <Link to="chat">Chat</Link>
              <Link to="broken">404</Link>
          </div>
          <div className="appContainer">
            <Routes>
              <Route path="/" element={<h1>Home Page</h1>} />
              <Route path="chat" element={<h1>Chat App</h1>}></Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
        <div className="footer">
          <p>Copyright 2022</p>
          <p>degen.one</p>
        </div>
      </div>
    </Router>
  );
};

export default App;
