interface IProps {
  onClick: any;
  text?: string;
  children?: JSX.Element[];
}

export const Btn = ({ onClick, text, children }: IProps) => {
  return (
    <button className="Btn" onClick={onClick}>
      {text}
      {children}
    </button>
  );
};
